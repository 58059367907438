<script setup>
import { Head, Link }  from '@inertiajs/vue3'
import { ClipboardDocumentIcon, CursorArrowRaysIcon, DocumentCheckIcon, FaceSmileIcon, SignalIcon } from '@heroicons/vue/24/outline'

/*
const primaryFeatures = [
    {
        name: 'Server monitoring',
        description:
            'Non quo aperiam repellendus quas est est. Eos aut dolore aut ut sit nesciunt. Ex tempora quia. Sit nobis consequatur dolores incidunt.',
        href: '#',
        icon: BoltIcon,
    },
    {
        name: 'Collaborate',
        description:
            'Vero eum voluptatem aliquid nostrum voluptatem. Vitae esse natus. Earum nihil deserunt eos quasi cupiditate. A inventore et molestiae natus.',
        href: '#',
        icon: UsersIcon,
    },
    {
        name: 'Task scheduling',
        description:
            'Et quod quaerat dolorem quaerat architecto aliquam accusantium. Ex adipisci et doloremque autem quia quam. Quis eos molestiae at iure impedit.',
        href: '#',
        icon: CalendarDaysIcon,
    },
]*/
const steps = [
	'Meld je aan of zoek jezelf op als bestaande klant',
	'Geef aan waar de klus uitgevoerd moet worden',
	'Vul de details van je klus in',
	'Kies of we de klus direct uitvoeren of eerst een offerte sturen',
	'Bevestig je klus en wij regelen de rest'
]

const secondaryFeatures = [
	{
		name: 'Altijd toegankelijk.',
		description: 'Meld je klus eenvoudig online, wanneer het jou uitkomt, 24/7.',
		icon: SignalIcon,
	},
	{
		name: 'Efficiënt en duidelijk proces.',
		description: 'Binnen vier snelle stappen alles geregeld, zonder gedoe.',
		icon: CursorArrowRaysIcon,
	},
	{
		name: 'Snelle actie of offerte.',
		description: 'Jij bepaalt: wil je eerst een offerte of dat we direct aan de slag gaan?',
		icon: DocumentCheckIcon, // calculator
	},
	{
		name: 'Alles onder controle.',
		description: 'Bevestig je klus en wij zorgen voor de volledige uitvoering.',
		icon: ClipboardDocumentIcon, // check
	},
	{
		name: 'Compleet ontzorgd.',
		description: 'Van kleine reparaties tot onderhoud, wij houden de wacht over jouw pand.',
		icon: FaceSmileIcon,
	},
]
/*
const stats = [
    { id: 1, name: 'Developers on the platform', value: '8,000+' },
    { id: 2, name: 'Daily requests', value: '900m+' },
    { id: 3, name: 'Uptime guarantee', value: '99.9%' },
    { id: 4, name: 'Projects deployed', value: '12m' },
]
const footerNavigation = {
    solutions: [
        { name: 'Hosting', href: '#' },
        { name: 'Data Services', href: '#' },
        { name: 'Uptime Monitoring', href: '#' },
        { name: 'Enterprise Services', href: '#' },
    ],
    support: [
        { name: 'Pricing', href: '#' },
        { name: 'Documentation', href: '#' },
        { name: 'Guides', href: '#' },
        { name: 'API Reference', href: '#' },
    ],
    company: [
        { name: 'About', href: '#' },
        { name: 'Blog', href: '#' },
        { name: 'Jobs', href: '#' },
        { name: 'Press', href: '#' },
        { name: 'Partners', href: '#' },
    ],
    legal: [
        { name: 'Claim', href: '#' },
        { name: 'Privacy', href: '#' },
        { name: 'Terms', href: '#' },
    ],
    social: [
        {
            name: 'Facebook',
            href: '#',
            icon: defineComponent({
                render: () =>
                    h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                        h('path', {
                            'fill-rule': 'evenodd',
                            d: 'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
                            'clip-rule': 'evenodd',
                        }),
                    ]),
            }),
        },
        {
            name: 'Instagram',
            href: '#',
            icon: defineComponent({
                render: () =>
                    h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                        h('path', {
                            'fill-rule': 'evenodd',
                            d: 'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
                            'clip-rule': 'evenodd',
                        }),
                    ]),
            }),
        },
        {
            name: 'X',
            href: '#',
            icon: defineComponent({
                render: () =>
                    h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                        h('path', {
                            d: 'M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z',
                        }),
                    ]),
            }),
        },
        {
            name: 'GitHub',
            href: '#',
            icon: defineComponent({
                render: () =>
                    h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                        h('path', {
                            'fill-rule': 'evenodd',
                            d: 'M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z',
                            'clip-rule': 'evenodd',
                        }),
                    ]),
            }),
        },
        {
            name: 'YouTube',
            href: '#',
            icon: defineComponent({
                render: () =>
                    h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                        h('path', {
                            'fill-rule': 'evenodd',
                            d: 'M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z',
                            'clip-rule': 'evenodd',
                        }),
                    ]),
            }),
        },
    ],
}*/
</script>

<template>
	<Head title="Klus melden" />

	<div class="bg-gray-900">
		<main>
			<!-- Hero section -->
			<div class="relative isolate overflow-hidden">
				<!--
                <svg
                    class="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
                            width="200"
                            height="200"
                            x="50%"
                            y="-1"
                            patternUnits="userSpaceOnUse"
                        >
                            <path
                                d="M.5 200V.5H200"
                                fill="none"
                            />
                        </pattern>
                    </defs>
                    <svg
                        x="50%"
                        y="-1"
                        class="overflow-visible fill-gray-800/20"
                    >
                        <path
                            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                            stroke-width="0"
                        />
                    </svg>
                    <rect
                        width="100%"
                        height="100%"
                        stroke-width="0"
                        fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"
                    />
                </svg>
                -->


				<div
					class="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
					aria-hidden="true"
				>
					<div
						class="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-gray-500 to-gray-900 opacity-20"
						style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)"
					/>
				</div>

				<!-- -->
				<div class="mx-auto max-w-7xl px-6 pt-10 lg:flex lg:px-8">
					<div class="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
						<img
							width="135"
							height="48"
							class="h-12"
							src="/images/wordmark.svg"
							alt="Pandwachters"
						>
					</div>
				</div>



				<div class="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-40 lg:flex lg:px-8 lg:pt-12 xl:pt-32">
					<div class="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
						<!--                        <img-->
						<!--                            class="h-12"-->
						<!--                            src="/images/wordmark.svg"-->
						<!--                            alt="Pandwachters"-->
						<!--                        >-->

						<div class="mt-4 sm:mt-32 lg:mt-16">
							<!--
                            <a
                                href="#"
                                class="inline-flex space-x-6"
                            >
                                <span class="rounded-full bg-primary-500/10 px-3 py-1 text-sm font-semibold leading-6 text-primary-400 ring-1 ring-inset ring-primary-500/20">Latest updates</span>
                                <span class="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-300">
                                    <span>Just shipped v1.0</span>
                                    <ChevronRightIcon
                                        class="h-5 w-5 text-gray-500"
                                        aria-hidden="true"
                                    />
                                </span>
                            </a>
                            -->
						</div>
						<h1 class="sm:mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl">
							Gedoe?<br>Zet hier direct een punt achter!
						</h1>
						<p class="mt-6 sm:text-lg leading-8 text-gray-300">
							Geen gedoe meer met klusjes in je pand. Meld je klus eenvoudig online aan en wij regelen de rest. Of het nu gaat om onderhoud of reparaties, bij Pandwachters is het in een paar stappen gedaan.
						</p>

						<ul
							role="list"
							:class="['text-gray-200 mt-8 space-y-3 leading-6 sm:mt-10']"
						>
							<li
								v-for="(step, index) in steps"
								:key="index"
								class="flex gap-x-3"
							>
								<span :class="['text-primary-400 font-bold h-6 w-5 flex-none']">
									{{ index + 1 }}.
								</span>
								<span>{{ step }}.</span>
							</li>
						</ul>

						<div class="mt-10 flex items-center gap-x-6">
							<Link
								:href="route('report.create')"
								class="rounded-md bg-primary-400 px-3.5 py-2.5 text-sm text-center font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 grow"
							>
								Meld je klus nu aan <span aria-hidden="true">→</span>
							</Link>
							<!--
                            <a
                                href="#"
                                class="text-sm font-semibold leading-6 text-white"
                            >Live demo <span aria-hidden="true">→</span></a>
                            -->
						</div>
					</div>
					<div class="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
						<div class="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
							<img
								src="/images/bus-right.webp"
								alt="App screenshot"
								width="2432"
								height="1442"
								class="w-[76rem]"
							>
						</div>
					</div>
				</div>
			</div>

			<!-- Feature section mt-8 sm:mt-16 -->
			<div class="mt-4 sm:mt-8">
				<div class="mx-auto max-w-7xl px-6 lg:px-8">
					<div class="mx-auto max-w-2xl sm:text-center">
						<h2 class="text-base font-semibold leading-7 text-primary-400">
							Zo zit het in elkaar
						</h2>
						<p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
							Zet een punt achter gedoe
						</p>
						<!--
                        <p class="mt-6 text-lg leading-8 text-gray-300">
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis.
                        </p>
                        -->
					</div>
				</div>
				<!--
                <div class="relative overflow-hidden pt-16">
                    <div class="mx-auto max-w-7xl px-6 lg:px-8">
                        <img
                            src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
                            alt="App screenshot"
                            class="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-white/10"
                            width="2432"
                            height="1442"
                        >
                        <div
                            class="relative"
                            aria-hidden="true"
                        >
                            <div class="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-gray-900 pt-[7%]" />
                        </div>
                    </div>
                </div>
                -->
				<div class="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
					<dl class="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
						<div
							v-for="feature in secondaryFeatures"
							:key="feature.name"
							class="relative pl-9"
						>
							<dt class="inline font-semibold text-white">
								<component
									:is="feature.icon"
									class="absolute left-1 top-1 h-5 w-5 text-primary-400"
									aria-hidden="true"
								/>
								{{ feature.name }}
							</dt>
							{{ ' ' }}
							<dd class="inline">
								{{ feature.description }}
							</dd>
						</div>
					</dl>
				</div>
			</div>

			<!-- Logo cloud mt-8 sm:mt-16 -->
			<!--
            <div class="mx-auto mt-32 max-w-7xl px-6 sm:mt-64 lg:px-8">
                <h2 class="text-center text-lg font-semibold leading-8 text-white">
                    Bedrijven die op ons vertrouwen
                </h2>
                <div class="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                    <img
                        class="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                        src="https://tailwindui.com/img/logos/158x48/transistor-logo-white.svg"
                        alt="Transistor"
                        width="158"
                        height="48"
                    >
                    <img
                        class="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                        src="https://tailwindui.com/img/logos/158x48/reform-logo-white.svg"
                        alt="Reform"
                        width="158"
                        height="48"
                    >
                    <img
                        class="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                        src="https://tailwindui.com/img/logos/158x48/tuple-logo-white.svg"
                        alt="Tuple"
                        width="158"
                        height="48"
                    >
                    <img
                        class="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
                        src="https://tailwindui.com/img/logos/158x48/savvycal-logo-white.svg"
                        alt="SavvyCal"
                        width="158"
                        height="48"
                    >
                    <img
                        class="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                        src="https://tailwindui.com/img/logos/158x48/statamic-logo-white.svg"
                        alt="Statamic"
                        width="158"
                        height="48"
                    >
                </div>
            </div>
            -->

			<!-- Feature section -->
			<!--
            <div class="mx-auto mt-32 max-w-7xl px-6 sm:mt-56 lg:px-8">
                <div class="mx-auto max-w-2xl text-center">
                    <h2 class="text-base font-semibold leading-7 text-primary-400">
                        Deploy faster
                    </h2>
                    <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                        Everything you need to deploy your app
                    </p>
                    <p class="mt-6 text-lg leading-8 text-gray-300">
                        Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in accusamus quisquam.
                    </p>
                </div>
                <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                    <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                        <div
                            v-for="feature in primaryFeatures"
                            :key="feature.name"
                            class="flex flex-col"
                        >
                            <dt class="text-base font-semibold leading-7 text-white">
                                <div class="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-primary-500">
                                    <component
                                        :is="feature.icon"
                                        class="h-6 w-6 text-white"
                                        aria-hidden="true"
                                    />
                                </div>
                                {{ feature.name }}
                            </dt>
                            <dd class="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-300">
                                <p class="flex-auto">
                                    {{ feature.description }}
                                </p>
                                <p class="mt-6">
                                    <a
                                        :href="feature.href"
                                        class="text-sm font-semibold leading-6 text-primary-400"
                                    >Learn more <span aria-hidden="true">→</span></a>
                                </p>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
            -->

			<!-- Feature section -->
			<!--
            <div class="mt-32 sm:mt-56">
                <div class="mx-auto max-w-7xl px-6 lg:px-8">
                    <div class="mx-auto max-w-2xl sm:text-center">
                        <h2 class="text-base font-semibold leading-7 text-primary-400">
                            Everything you need
                        </h2>
                        <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                            No server? No problem.
                        </p>
                        <p class="mt-6 text-lg leading-8 text-gray-300">
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis.
                        </p>
                    </div>
                </div>
                <div class="relative overflow-hidden pt-16">
                    <div class="mx-auto max-w-7xl px-6 lg:px-8">
                        <img
                            src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
                            alt="App screenshot"
                            class="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-white/10"
                            width="2432"
                            height="1442"
                        >
                        <div
                            class="relative"
                            aria-hidden="true"
                        >
                            <div class="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-gray-900 pt-[7%]" />
                        </div>
                    </div>
                </div>
                <div class="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
                    <dl class="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
                        <div
                            v-for="feature in secondaryFeatures"
                            :key="feature.name"
                            class="relative pl-9"
                        >
                            <dt class="inline font-semibold text-white">
                                <component
                                    :is="feature.icon"
                                    class="absolute left-1 top-1 h-5 w-5 text-primary-500"
                                    aria-hidden="true"
                                />
                                {{ feature.name }}
                            </dt>
                            {{ ' ' }}
                            <dd class="inline">
                                {{ feature.description }}
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
            -->

			<!-- Stats -->
			<!--
            <div class="mx-auto mt-32 max-w-7xl px-6 sm:mt-56 lg:px-8">
                <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                    <h2 class="text-base font-semibold leading-8 text-primary-400">
                        Our track record
                    </h2>
                    <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                        Trusted by thousands of developers&nbsp;worldwide
                    </p>
                    <p class="mt-6 text-lg leading-8 text-gray-300">
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.
                    </p>
                </div>
                <dl class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-10 text-white sm:mt-20 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4">
                    <div
                        v-for="stat in stats"
                        :key="stat.id"
                        class="flex flex-col gap-y-3 border-l border-white/10 pl-6"
                    >
                        <dt class="text-sm leading-6">
                            {{ stat.name }}
                        </dt>
                        <dd class="order-first text-3xl font-semibold tracking-tight">
                            {{ stat.value }}
                        </dd>
                    </div>
                </dl>
            </div>
            -->

			<!-- CTA section -->
			<div class="relative isolate mt-32 px-6 py-32 sm:mt-56 sm:py-40 lg:px-8">
				<!--
                <svg
                    class="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="1d4240dd-898f-445f-932d-e2872fd12de3"
                            width="200"
                            height="200"
                            x="50%"
                            y="0"
                            patternUnits="userSpaceOnUse"
                        >
                            <path
                                d="M.5 200V.5H200"
                                fill="none"
                            />
                        </pattern>
                    </defs>
                    <svg
                        x="50%"
                        y="0"
                        class="overflow-visible fill-gray-800/20"
                    >
                        <path
                            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                            stroke-width="0"
                        />
                    </svg>
                    <rect
                        width="100%"
                        height="100%"
                        stroke-width="0"
                        fill="url(#1d4240dd-898f-445f-932d-e2872fd12de3)"
                    />
                </svg>
                -->
				<div
					class="absolute inset-x-0 top-10 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
					aria-hidden="true"
				>
					<div
						class="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-gray-500 to-gray-900 opacity-20"
						style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)"
					/>
				</div>
				<div class="mx-auto max-w-2xl text-center">
					<h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">
						Altijd klaar voor jouw klus!
					</h2>
					<p class="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
						Onze kluswacht staat 24/7 voor je klaar om al het kleine onderhoud in je pand zonder gedoe te regelen.
					</p>
					<div class="mt-10 flex items-center justify-center gap-x-6">
						<Link
							:href="route('report.create')"
							class="rounded-md bg-primary-400 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 grow sm:grow-0"
						>
							Klus aanmelden
						</Link>
						<a
							href="https://pandwachters.nl"
							class="text-sm font-semibold leading-6 text-white"
							target="_blank"
						>Nog nieuwsgierig? <span aria-hidden="true">→</span></a>
					</div>
				</div>
			</div>
		</main>

		<!-- Footer -->
		<footer
			aria-labelledby="footer-heading"
			class="relative"
		>
			<h2
				id="footer-heading"
				class="sr-only"
			>
				Footer
			</h2>
			<div class="mx-auto max-w-7xl px-6 pb-8 pt-4 lg:px-8">
				<div class="border-t border-white/10 pt-8 md:flex md:items-center md:justify-between">
					<div class="flex space-x-6 md:order-2">
						<!--
                        <a
                            v-for="item in footerNavigation.social"
                            :key="item.name"
                            :href="item.href"
                            class="text-gray-500 hover:text-gray-400"
                        >
                            <span class="sr-only">{{ item.name }}</span>
                            <component
                                :is="item.icon"
                                class="h-6 w-6"
                                aria-hidden="true"
                            />
                        </a>
                        -->
					</div>
					<p class="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">
						&copy; {{ new Date().getFullYear() }} Pandwachters
					</p>
				</div>
			</div>
		</footer>
	</div>
</template>
